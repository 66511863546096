import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import dayjs from "dayjs";

import { Bottomsheet } from "../../components/Bottomsheet";
import { Button } from "../../components/Button";
import { StaffCapsule } from "../../components/Capsule/StaffCapsule";
import { Footer } from "../../components/Footer";
import { MarkdownField } from "../../components/Markdown";
import config from "../../config";
import { getDeviceOs } from "../../utils/helpers";
import { NotFound } from "../Common/NotFound";
import "./index.css";

export const Event = () => {
  // Contexts
  const params = useParams();
  const { t } = useTranslation();

  // Query
  const { data } = useQuery(QUERY, { variables: { id: params?.eventId } });

  const appLink = config.appLink[getDeviceOs() ?? "ios"];

  if (!data || !data?.event) {
    return <NotFound />;
  }

  // ==

  const event = data?.event;
  const firstActivatedAt = event?.firstActivatedAt
    ? dayjs(event?.firstActivatedAt).format("YYYY-MM-DD HH:mm")
    : "";

  return (
    <div>
      <Helmet>
        <title>FANS - {event.noticeTitle}</title>
        <meta property="og:title" content={`FANS - ${event.noticeTitle}`} />
        <meta property="og:description" content={event.noticeBody} />
      </Helmet>
      <div className="event-content">
        <h2 className="title">{event.noticeTitle}</h2>
        <div className="item">
          <div className="profile">
            <img
              src={event.group.defaultProfileImage.profileThumbnailUrl}
              className="profile"
            />
            <span className="group-name">{event.group.name}</span>
            <StaffCapsule text="STAFF" />
          </div>
          {firstActivatedAt ? <span className="date">{firstActivatedAt}</span> : null}
        </div>
        <div className="divider" />
        <MarkdownField key={`${event.id}-${event.noticeTitle}`}>
          {event.noticeBody}
        </MarkdownField>
      </div>
      <Footer divider />
      <Bottomsheet>
        <Link to={appLink} target="_blank">
          <Button className="cta-button">{t("web.page.event.cta")}</Button>
        </Link>
      </Bottomsheet>
    </div>
  );
};

// ==

const QUERY = gql`
  query eoticeDetailForWeb($id: ID!) {
    event(filter: { id_Overlap: [$id] }) {
      group {
        id
        name
        defaultProfileImage {
          ... on Image {
            key
            mime
            url
            height
            width
            profileThumbnailUrl: thumbnailUrl(mode: CROP, width: 150, height: 150)
          }
        }
      }
      noticeTitle
      noticeBody
      firstActivatedAt
      isActive
    }
  }
`;
