import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { initReactI18next } from "react-i18next";
import { useSSR } from "react-i18next";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { from } from "@apollo/client/link/core";
import { HttpLink } from "@apollo/client/link/http";
import { ApolloProvider } from "@apollo/client/react/context";

import * as Sentry from "@sentry/react";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import App from "./App";
import config from "./config";
import "./index.css";
import enTranslation from "./locales/en.json";
import jaTranslation from "./locales/ja.json";
import koTranslation from "./locales/ko.json";
import zhTranslation from "./locales/zh-CN.json";

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.sentryEnvironment,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    enableTracing: true,
    tracePropagationTargets: [config.graphqlEndpoint],
  });
}

const Wrapper = () => {
  // Loading from server-side
  const i18nStore = window.initialI18nStore;
  const language = window.initialLanguage;
  const apolloState = window.__APOLLO_STATE__;
  const guid = window.guid;

  // ==

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      ...config.i18nOptions,
      // This is not strictly necessary, but mobile safari has a bug of not property
      // load server-side translations
      resources: {
        en: { translation: enTranslation },
        ko: { translation: koTranslation },
        ja: { translation: jaTranslation },
        "zh-CN": { translation: zhTranslation },
      },
    });

  useSSR(i18nStore, language);

  // ==

  const httpLink = new HttpLink({
    uri: config.graphqlEndpoint,
  });

  const authLink = setContext((_, { headers }) => {
    const language = i18n.language;
    return {
      headers: {
        ...headers,
        "j-context": config.defaultContext,
        ...(guid && { "j-guid": guid }),
        ...(language && { "j-language": language }),
      },
    };
  });

  const client = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache().restore(apolloState),
    ssrForceFetchDelay: 100,
  });

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  );
};

ReactDOM.hydrateRoot(document.getElementById("root"), <Wrapper />);
