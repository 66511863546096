import { Helmet } from "react-helmet";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import { Footer } from "../../components/Footer";
import "./index.css";

export const Terms = () => {
  const { data } = useQuery(QUERY);

  if (!data) {
    return null;
  }

  // ==

  const agreement = data.agreements.objects[0];

  return (
    <div className="terms">
      <Helmet>
        <title>FANS - Terms of Use</title>
        <meta property="og:title" content="FANS - Terms of Use" />
      </Helmet>
      <div className="terms-content">
        <h2>{agreement.title}</h2>
        <p style={{ whiteSpace: "pre-line" }}>{agreement.body}</p>
      </div>
      <Footer divider />
    </div>
  );
};

// ==

const QUERY = gql`
  query agreementsForTerms {
    agreements(
      filter: { category_Overlap: [TERM] }
      page: { first: 1 }
      sort: [{ type: CREATED_AT, direction: DESC }]
    ) {
      objects {
        id
        title
        body
      }
    }
  }
`;
