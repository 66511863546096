import { useEffect } from "react";
import { Link } from "react-router-dom";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import logoBlack from "../../assets/logoBlack.png";
import logoWhite from "../../assets/logoWhite.png";
import { Footer } from "../../components/Footer";
import config from "../../config";
import { useApp } from "../../hooks/app";
import "./index.css";

export const Home = () => {
  const { data } = useQuery(QUERY);
  const { colorMode, resetPrimaryColor } = useApp();

  useEffect(() => {
    resetPrimaryColor();
  }, [resetPrimaryColor]);

  if (!data) {
    return null;
  }

  const groups = data?.groups?.objects;

  return (
    <div>
      <Link to="/" className="home-header">
        <img
          src={colorMode === config.darkColorMode ? logoWhite : logoBlack}
          className="home-logo"
        />
      </Link>
      <div className="home-group-list">
        {groups?.map((group, i) => (
          <Link
            key={i}
            //to={`/community/${group.code}`}
            className={`home-group ${i % 2 === 1 ? "odd" : ""}`}
          >
            <div className="home-group-name">{group.name}</div>
            <img src={group.mainGridImage.thumbnailUrl} className="home-group-image" />
          </Link>
        ))}
      </div>
      <Footer />
    </div>
  );
};

// ==

const QUERY = gql`
  query {
    groups {
      objects {
        id
        code
        name
        mainGridImage {
          thumbnailUrl(width: 500, height: 500, mode: CROP)
        }
      }
    }
  }
`;
