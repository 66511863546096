const config = {
  graphqlEndpoint: import.meta.env.VITE_MISSA_GRAPHQL_ENDPOINT,
  metaEndpoint: import.meta.env.VITE_MISSA_META_ENDPOINT,
  sentryDsn: import.meta.env.VITE_MISSA_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_MISSA_SENTRY_ENVIRONMENT,
  darkColorMode: "dark",
  lightColorMode: "light",
  i18nOptions: {
    supportedLngs: ["en", "ko"],
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    detection: {
      caches: ["cookie"],
      lookupCookie: "language",
    },
  },
  contactEmail: "fans_help@jype.com",
  defaultMetaTitle: "FANS - For Fans & Artists",
  defaultMetaDescription:
    "Join a community of artists you care about and create special memories",
  defaultOgImage: "https://asset.jypfans.com/images/fans-og-image.png",
  appLink: {
    ios: "https://apps.apple.com/us/app/fans-for-fans-artists/id6474999628",
    android: "https://play.google.com/store/apps/details?id=com.jype.fans",
  },
  helpLink: "https://jypkorea.zendesk.com/hc/requests/new",
  defaultContext: "web",
  guidKey: "guid",
};

export default config;
