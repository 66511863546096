import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import dayjs from "dayjs";

import { StaffCapsule } from "../../components/Capsule/StaffCapsule";
import { Footer } from "../../components/Footer";
import { MarkdownField } from "../../components/Markdown";
import { NotFound } from "../Common/NotFound";
import "./index.css";

export const Notice = () => {
  // Contexts
  const params = useParams();

  // Query
  const { data } = useQuery(QUERY, { variables: { id: params?.noticeId } });

  if (!data || !data?.notice) {
    return <NotFound />;
  }

  // ==

  const notice = data?.notice;
  const firstActivatedAt = notice?.firstActivatedAt
    ? dayjs(notice?.firstActivatedAt).format("YYYY-MM-DD HH:mm")
    : "";

  return (
    <div>
      <Helmet>
        <title>FANS - {notice.title}</title>
        <meta property="og:title" content={`FANS - ${notice.title}`} />
        <meta property="og:description" content={notice.body} />
      </Helmet>
      <div className="notice-content">
        <h2 className="title">{notice.title}</h2>
        <div className="item">
          <div className="profile">
            <img
              src={notice.group.defaultProfileImage.profileThumbnailUrl}
              className="profile"
            />
            <span className="group-name">{notice.group.name}</span>
            <StaffCapsule text="STAFF" />
          </div>
          {firstActivatedAt ? <span className="date">{firstActivatedAt}</span> : null}
        </div>
        <div className="divider" />
        <MarkdownField key={`${notice.id}-${notice.title}`}>
          {notice.body}
        </MarkdownField>
      </div>
      <Footer divider />
    </div>
  );
};

// ==

const QUERY = gql`
  query NoticeDetailForWeb($id: ID!) {
    notice(filter: { id_Overlap: [$id] }) {
      group {
        id
        name
        defaultProfileImage {
          ... on Image {
            key
            mime
            url
            height
            width
            profileThumbnailUrl: thumbnailUrl(mode: CROP, width: 150, height: 150)
          }
        }
      }
      title
      body
      firstActivatedAt
      isActive
    }
  }
`;
