import { Fragment } from "react";
import { Link } from "react-router-dom";

import { useApolloClient } from "@apollo/client/react/hooks";

import { useApp } from "../../hooks/app";
import "./index.css";

export const LanguageSelector = () => {
  const client = useApolloClient();
  const { language, languages, setLanguage } = useApp();

  return (
    <div className="language-list">
      {languages?.map((l, i) => (
        <Fragment key={i}>
          <Link
            className={`language ${language === l?.code && "active"}`}
            onClick={() => {
              setLanguage(l.code);
              client.resetStore();
            }}
          >
            {l?.nativeName.split(" ")[0]}
          </Link>
          {i !== languages.length - 1 && <a className="language-delimiter">·</a>}
        </Fragment>
      ))}
    </div>
  );
};
