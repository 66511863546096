import "./index.css";

export const Button = ({
  backgroundColor = "black",
  textColor = "white",
  className = "",
  style = undefined,
  disabled = false,
  children,
  ...props
}) => {
  return (
    <button
      className={`button ${className}`}
      style={{
        backgroundColor: disabled ? "#E8E8E8" : backgroundColor,
        color: disabled ? "#C8C8C8" : textColor,
        cursor: disabled ? "unset" : "pointer",
        ...style,
      }}
      {...props}
      disabled={disabled}
    >
      <span className="button-icon"></span>
      <span className="button-text">{children}</span>
    </button>
  );
};
