import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import config from "../../config";
import { LanguageSelector } from "../LanguageSelector";
import "./index.css";

export const Footer = ({ divider = false, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className="footer" {...props}>
      {divider && <hr className="footer-divider" />}
      <p>
        <Trans i18nKey="web.component.Footer.signature" />
      </p>
      <p>
        <Link to="/terms">{t("web.component.Footer.terms")}</Link>
        <a className="footer-delimiter">·</a>
        <Link to="/privacy">{t("web.component.Footer.privacy")}</Link>
        <a className="footer-delimiter">·</a>
        <Link to={config.helpLink} target="_blank">
          {t("web.component.Footer.contact")}
        </Link>
      </p>
      <div className="footer-language-selector">
        <LanguageSelector />
      </div>
    </div>
  );
};
