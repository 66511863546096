import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import dayjs from "dayjs";

import { Footer } from "../../components/Footer";
import { MarkdownField } from "../../components/Markdown";
import { NotFound } from "../Common/NotFound";
import "./index.css";

export const SettingsNotice = () => {
  // Contexts
  const params = useParams();

  // Query
  const { data } = useQuery(QUERY, { variables: { id: params?.noticeId } });

  if (!data || !data?.announcement) {
    return <NotFound />;
  }

  // ==

  const announcement = data?.announcement;
  const firstActivatedAt = announcement?.firstActivatedAt
    ? dayjs(announcement?.firstActivatedAt).format("YYYY-MM-DD HH:mm")
    : "";

  return (
    <div>
      <Helmet>
        <title>FANS - {announcement.title}</title>
        <meta property="og:title" content={`FANS - ${announcement.title}`} />
        <meta property="og:description" content={announcement.body} />
      </Helmet>
      <div className="notice-content">
        <h2 className="title">{announcement.title}</h2>
        <div className="item">
          {firstActivatedAt ? <span className="date">{firstActivatedAt}</span> : null}
        </div>
        <div className="divider" />
        <MarkdownField key={`${announcement.id}-${announcement.title}`}>
          {announcement.body}
        </MarkdownField>
      </div>
      <Footer divider />
    </div>
  );
};

// ==

const QUERY = gql`
  query AnnouncementDetailForWeb($id: ID!) {
    announcement(filter: { id_Overlap: [$id] }) {
      id
      title
      body
      firstActivatedAt
    }
  }
`;
