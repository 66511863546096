import { Helmet } from "react-helmet";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import { Footer } from "../../components/Footer";
import "./index.css";

export const Privacy = () => {
  const { data } = useQuery(QUERY);

  if (!data) {
    return null;
  }

  // ==

  const agreement = data.agreements.objects[0];

  return (
    <div className="privacy">
      <Helmet>
        <title>FANS - Privacy Policy</title>
        <meta property="og:title" content="FANS - Privacy Policy" />
      </Helmet>
      <div className="privacy-content">
        <h2>{agreement.title}</h2>
        <p style={{ whiteSpace: "pre-line" }}>{agreement.body}</p>
      </div>
      <Footer divider />
    </div>
  );
};

// ==

const QUERY = gql`
  query agreementsForPrivacy {
    agreements(
      filter: { category_Overlap: [PRIVACY] }
      page: { first: 1 }
      sort: [{ type: CREATED_AT, direction: DESC }]
    ) {
      objects {
        id
        title
        body
      }
    }
  }
`;
