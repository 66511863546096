import { useTranslation } from "react-i18next";

import { Footer } from "../../components/Footer";
import "./index.css";

export const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found">
      <div className="not-found wrapper">
        <h1 className="not-found title">{t("web.page.Common.NotFound.title")}</h1>
        <p className="not-found description">
          {t("web.page.Common.NotFound.description")}
        </p>
      </div>
      <Footer divider />
    </div>
  );
};
