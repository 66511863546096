import camelCase from "camelcase";
import equal from "deep-equal";

import config from "../config";

// Checker

export const isBoolean = (value) => typeof value === "boolean";
export const isString = (str) => typeof str === "string" || str instanceof String;
export const isObject = (val) =>
  typeof val === "object" && !Array.isArray(val) && val !== null;
export const isArray = (val) => Array.isArray(val);
export const isFunction = (val) => typeof val === "function";
export const isDefined = (val) => typeof val !== "undefined";
export const isEmptyString = (val) => val === "";
export const isEmptyArray = (val) => isArray(val) && !val.length;
export const isUndefined = (val) => val === undefined;
export const isNull = (val) => val === null;
export const isNullOrUndefined = (val) => isNull(val) || isUndefined(val);
export const isEmptyObject = (val) => isObject(val) && Object.keys(val).length === 0;
export const isEmpty = (val) =>
  isUndefined(val) ||
  isNull(val) ||
  isEmptyString(val) ||
  isEmptyArray(val) ||
  isEmptyObject(val);

// Format

export const formatNumber = (value) => new Intl.NumberFormat().format(value);
export const formatDuration = (value) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.floor(value % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    const formattedHours = String(hours).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

// Case Conversion

export const toCamelCase = camelCase;
export const camelToHyphenCase = (val) =>
  val.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
export const camelToSnakeCase = (val) =>
  val.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
export const snakeToTitleCase = (val) =>
  val
    .split(/_+/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join("");
export const camelToTitleCase = (val) => snakeToTitleCase(camelToSnakeCase(val));
export const snakeToHumanCase = (val) =>
  val
    .split(/_+/)
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
export const camelToHumanCase = (val) => snakeToHumanCase(camelToSnakeCase(val));

export const capitalize = (value) =>
  !value || typeof value !== "string"
    ? ""
    : value.charAt(0).toUpperCase() + value.slice(1);

// Comparision

export const deepEqual = equal;
export const arrayEquals = (arr1, arr2) =>
  arr1.length == arr2.length && arr1.every((e, i) => e == arr2[i]);

// Conversion

export const stringToBoolean = (val) =>
  val === "true" ? true : val === "false" ? false : null;
export const booleanToString = (val) =>
  val === true ? "true" : val === false ? "false" : "";

// Url

export const normalizeUrl = (url) => {
  return url ? new URL(url, config.graphqlEndpoint).toString() : null;
};
export const getRelativeUrl = (url = undefined) => {
  const u = new URL(url ?? window.location.href, window.location.origin);
  return [u.pathname, u.search].join("");
};
export const getSearchParam = (key, url = undefined) =>
  new URL(url ?? window.location.href, window.location.origin).searchParams.get(key);
export const addSearchParam = (key, value, url = undefined) => {
  const u = new URL(url ?? window.location.href, window.location.origin);
  u.searchParams.append(key, value);
  return u.href;
};
export const extractFileName = (value) => decodeURIComponent(value.split("/").pop());

// Array

export const compact = (value) => (Array.isArray(value) ? value.filter(Boolean) : []);

// Object

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
export const pick = (obj, keys) =>
  keys.reduce((result, key) => {
    if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
export const stripDunderKeys = (obj) => {
  if (isArray(obj)) {
    return obj.map((val) => stripDunderKeys(val));
  } else if (isObject(obj)) {
    return Object.keys(obj).reduce((newObj, key) => {
      if (!key.startsWith("__")) {
        newObj[key] = stripDunderKeys(obj[key]);
      }
      return newObj;
    }, {});
  }
  return obj;
};
export const stripEmptyValues = (obj) => {
  if (isArray(obj)) {
    return obj.map((val) => stripEmptyValues(val));
  } else if (isObject(obj)) {
    return Object.keys(obj).reduce((newObj, key) => {
      if (!isEmpty(obj[key])) {
        newObj[key] = stripEmptyValues(obj[key]);
      }
      return newObj;
    }, {});
  }
  return obj;
};
export const getByPath = (obj, path, defaultValue) => {
  if (!path || !isObject(obj)) {
    return defaultValue;
  }

  const result = compact(path.split(/[,[\].]+?/)).reduce(
    (result, key) => (isNullOrUndefined(result) ? result : result[key]),
    obj,
  );

  return isUndefined(result) || result === obj
    ? isUndefined(obj[path])
      ? defaultValue
      : obj[path]
    : result;
};
export const setByPath = (obj, path, value) => {
  let index = -1;
  const tempPath = isKey(path) ? [path] : stringToPath(path);
  const length = tempPath.length;
  const lastIndex = length - 1;

  while (++index < length) {
    const key = tempPath[index];
    let newValue = value;

    if (index !== lastIndex) {
      const objValue = obj[key];
      newValue =
        isObject(objValue) || Array.isArray(objValue)
          ? objValue
          : !isNaN(+tempPath[index + 1])
            ? []
            : {};
    }
    obj[key] = newValue;
    obj = obj[key];
  }
  return obj;
};
const stringToPath = (input) => compact(input.replace(/["|']|\]/g, "").split(/\.|\[/));
const isKey = (value) => /^\w*$/.test(value);

// Function

export const resolveCallable = (callable, ...args) =>
  isFunction(callable) ? callable(...args) : callable;

// Etc

export const isBrowser = () =>
  typeof window !== "undefined" && typeof window.document !== "undefined";

export const isServer = () => !isBrowser();

export const getDeviceOs = () => {
  if (isServer()) {
    return null;
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Check for iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "ios";
  }
  // Check for Android
  else if (/android/i.test(userAgent)) {
    return "android";
  }

  return null;
};
