import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { gql } from "@apollo/client/core";
import { useQuery } from "@apollo/client/react/hooks";

import config from "../config";
import { isBrowser } from "../utils/helpers";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const isSystemPreferenceDark =
    isBrowser() && window.matchMedia("(prefers-color-scheme: dark)").matches;
  const systemPreference = isSystemPreferenceDark
    ? config.darkColorMode
    : config.lightColorMode;

  const [colorMode, setColorMode] = useState(config.lightColorMode);
  const [primaryColor, setPrimaryColor] = useState(getDefaultPrimaryColor(colorMode));
  const [buttonTextColor, setButtonTextColor] = useState(
    getDefaultButtonTextColor(colorMode),
  );
  const [hideGradientOnScroll, setHideGradientOnScroll] = useState(true);

  const { i18n } = useTranslation();
  const { data } = useQuery(QUERY, {
    variables: { codes: config.i18nOptions.supportedLngs },
  });

  useEffect(() => {
    if (colorMode === config.lightColorMode) {
      document.querySelector(".content")?.classList.remove("dark-mode");
    } else {
      document.querySelector(".content")?.classList.add("dark-mode");
    }
  }, [colorMode]);

  if (!data) {
    return null;
  }

  // ==

  const toggleColorMode = () => {
    if (colorMode === config.lightColorMode) {
      setColorMode(config.darkColorMode);
    } else {
      setColorMode(config.lightColorMode);
    }
  };
  const setLanguage = (language) => {
    i18n.changeLanguage(language);
  };
  const resetPrimaryColor = () => setPrimaryColor(getDefaultPrimaryColor(colorMode));

  const language = i18n.language;
  const languages = config.i18nOptions.supportedLngs.map((code) =>
    data.allLanguages.find((language) => language.code === code),
  );

  return (
    <AppContext.Provider
      value={{
        colorMode,
        setColorMode,
        toggleColorMode,
        // ==
        primaryColor,
        setPrimaryColor,
        resetPrimaryColor,
        // ==
        buttonTextColor,
        setButtonTextColor,
        // ==
        language,
        languages,
        setLanguage,
        // ==
        hideGradientOnScroll,
        setHideGradientOnScroll,
      }}
      {...props}
    />
  );
};

// ==

const getDefaultPrimaryColor = (colorMode) =>
  colorMode == config.lightColorMode ? "black" : "white";

const getDefaultButtonTextColor = (colorMode) =>
  colorMode == config.lightColorMode ? "white" : "black";

// ==

const QUERY = gql`
  query allLanguagesForApp($codes: [String!]) {
    allLanguages(filter: { code_Overlap: $codes }) {
      id
      code
      nativeName
    }
  }
`;
